import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Upload from '../../../../../img/UploadImageIcon.svg';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import { addLifeEventPhotos } from '../../../../../redux/actions';
import styles from './UploadImages.module.css';
import UpgradePlanModal from '../../../../../components/Modals/UpgradePlanModal';
import isEmpty from 'lodash.isempty';

const UploadImages = ({ handleShowImages }) => {
  const inputFile = useRef();
  const dispatch = useDispatch();
  const [openUpgradePlanModal, setOpenUpgradePlanModal] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const canAddPhotos = useSelector(
    (state) => state.user.activeSubscription?.can_add_timeline_photos
  );
  const lifeEventPhotos = useSelector((state) => state.grave.lifeEventPhotos);
  const biggerImage = () =>
    showUserNotification(
      'Last opp et bilde som ikke er større enn 20MB.',
      'warning'
    );

  const choseCoverPhotoFromList = (photos) => {
    if (lifeEventPhotos.length > 0) {
      const newPhotos = [...lifeEventPhotos, ...photos];
      dispatch(addLifeEventPhotos(newPhotos));
    } else {
      dispatch(addLifeEventPhotos(photos));
    }
  };

  const selectTheImages = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const imagesArray = Array.from(e.target.files);
      const selectedImages = [];

      const generateUniqueId = () => {
        return Date.now().toString() + Math.floor(Math.random() * 1000);
      };

      const processImage = (file, result) => {
        if (file.size / (1024 * 1024) <= 20) {
          selectedImages.push({
            file_url: result,
            id: generateUniqueId(),
            file: file,
            category: 'timeline',
          });
        } else {
          biggerImage();
        }
        if (selectedImages.length === imagesArray.length) {
          choseCoverPhotoFromList(selectedImages);
        }
      };

      imagesArray.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event) => processImage(file, event.target.result);
        reader.readAsDataURL(file);
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(lifeEventPhotos)) {
      handleShowImages();
    }
  }, [lifeEventPhotos]);

  const clickOnHideInput = () => {
    if (canAddPhotos) {
      inputFile.current.click();
    } else {
      setOpenUpgradePlanModal(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const droppedFiles = e.dataTransfer.files;
    selectTheImages({ target: { files: droppedFiles } });
  };

  return (
    <>
      <UpgradePlanModal
        isOpen={openUpgradePlanModal}
        onClose={() => setOpenUpgradePlanModal(false)}
        body='upload_images_body'
        title='upgrade_modal_heading'
      />
      <div
        className={
          isDraggingOver
            ? styles['drop-image']
            : styles['cropper-modal_content-input-image']
        }
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragLeave={() => setIsDraggingOver(false)}
      >
        <div className={styles['cropper-modal_content-input-image_image']}>
          <input
            multiple
            ref={inputFile}
            type='file'
            accept='image/jpeg, image/jpg, image/png'
            className={styles['cropper-modal_content-input-image_image-input']}
            onChange={selectTheImages}
          />
          <div className={styles['upload-image']}>
            <div
              className={styles['upload-image-input']}
              onClick={clickOnHideInput}
            >
              <img loading='lazy' src={Upload} alt='UploadImage' />
              <span className={styles['upload-image-text']}>
              Dra bildene hit, eller trykk for å velge filer.
              </span>
            </div>
            <div className={styles['image-text-message']}>
              Bildet bør ikke være større enn 20MB
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadImages;
